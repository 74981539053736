<template>
    <div class="card" :style="{ backgroundColor: cardColor }">
      <div class="card-image-container">
        <i class="fas fa-cross card-icon white-icon"></i>
      </div>
      <div class="card-content">
        <p style="font-size:22px;height:50px">{{ nome.toUpperCase() }}</p>
        <p><b>Falecimento: </b>{{ falecimento }}</p>
        <p><b>Sepultamento: </b> {{ sepultamento }}</p>
        <p><b>Tipo: </b> {{ quadra }}  <b> Quadra: </b>{{ letra }}</p>
        <p><b>Nº: </b>{{ jazigo }}</p>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      nome: String,
      falecimento: String,
      sepultamento: String,
      quadra: String,
      letra: String,
      jazigo: String,
      cardColor: String,
    },
    methods: {
    formatDate(dateString) {
      const date = new Date(dateString);
      const day = date.getDate();
      const month = date.getMonth() + 1; // Lembre-se que os meses em JavaScript começam em 0 (janeiro é 0).
      const year = date.getFullYear();
      const formattedDate = `${day < 10 ? '0' : ''}${day}-${month < 10 ? '0' : ''}${month}-${year}`;
      return formattedDate;
    },
    formatTime(dateTimeString) {
      const time = new Date(dateTimeString);
      const options = { hour: '2-digit', minute: '2-digit' };
      return time.toLocaleTimeString('pt-BR', options);
    },
  },
  };
  </script>
  
  <style>
  /* Estilize o card conforme necessário */
  .card {
    display: inline-block;
    width: 420px;
    height: 300px;
    margin: 10px;
    border-radius: 5px;
    text-align: center;
  }
  
  .card-icon {
    margin-top:10px;
    font-size: 26px; /* Tamanho da fonte para o ícone */
  }
  
  .white-icon {
    color: white; /* Cor branca para o ícone */
  }
  
  .card-content {
    color: white;
    padding: 5px;
  }

  .card-image {
    width: 100px;
    height: 100px;
    margin: 10px auto;
  }
  
  .card-content {
    color: white;
    padding: 5px;
  }
  </style>
  